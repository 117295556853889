<template>
  <div
    id="offcanvasChoosePublications"
    class="offcanvas offcanvas-end offcanvas-end--big-panel d-flex"
    :class="{ show: visible }"
    :style="{ visibility: visible ? 'visible' : 'hidden' }"
    tabindex="-1"
    aria-labelledby="offcanvasChoosePublicationsLabel"
  >
    <div class="offcanvas-header">
      <h3
        id="offcanvasChoosePublicationsLabel"
        class="offcanvas-title"
      >
        <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="git-commit" /></span> Choose publications
      </h3>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="closeOffcanva"
      />
    </div>
    <div class="offcanvas-body">
      <h4>Search publication</h4>
      <div class="row d-flex alig-items-end">
        <div class="col-sm-3">
          <label
            for=""
            class="form-label"
          >Publication reference</label>
          <input
            v-model="filters['full_references']"
            type="text"
            class="form-control"
            @change="saveFilter($event.target.value, 'full_reference')"
          >
        </div>
        <div class="col-sm-6">
          <div class="d-flex align-items-end h-100">
            <button
              class="btn btn-dark me-1"
              @click="applyFilters()"
            >
              <i data-feather="search" />
            </button>
            <button
              class="btn btn-outline-dark"
              @click="cleanFilters()"
            >
              <i data-feather="refresh-ccw" />
            </button>
          </div>
        </div>
      </div>

      <hr>

      <div class="card-header d-flex align-items-center justify-content-between card--table">
        <div>
          <h4><strong>{{ publicationsTotal }}</strong> publications</h4>
        </div>
        <div>
          <div class="btn-group dropup dropdown-icon-wrapper">
            <b-dropdown
              variant="button"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <button
                  id="dropdownMenuButton"
                  type="button"
                  class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Action multiple
                </button>
              </template>
              <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                <b-dropdown-item @click="setPublications('add', 'items')">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasAddMemoir"
                    aria-controls="offcanvasAddMemoir"
                  >Add to Memoir</a>
                </b-dropdown-item>
                <b-dropdown-item @click="setPublications('remove', 'items')">
                  <a
                    data-bs-toggle="offcanvas"
                    data-bs-target="#removePublicationsMemoir"
                    aria-controls="removePublicationsMemoir"
                  >Remove from Memoir</a>
                </b-dropdown-item>
              </div>
            </b-dropdown>
            <!-- <button
              type="button"
              class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action multiple
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a
                class="dropdown-item"
                @click="setPublications('add', 'items')"
              >Add to Memoir</a>
              <a
                class="dropdown-item"
                @click="setPublications('remove', 'items')"
              >Remove to Memoir</a>
            </div> -->
          </div>
        </div>
      </div>

      <div class="table-responsive-sm">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  @click="selectAll($event)"
                >
              </th>
              <th>Title</th>
              <th>Year</th>
              <th>Status</th>
              <th>Selected to Memoir</th>
              <th>Order in Memoir</th>
              <th>Characters</th>
              <th>Signed as ICREA</th>
              <th>Comments</th>
              <th class="text-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(publication, index) in publications"
              :key="index"
              :class="publication.ready_to_memoir === 'ready' ? 'table-success'
                : (publication.ready_to_memoir === 'not_yet' ? 'table-warning' : '')"
            >
              <!-- <tr
              v-for="(publication, index) in publications"
              :key="index"
              :class="publicationsFromMemoir.findIndex(x => x.id === publication.id && x.ready_to_memoir !== 'ready') >= 0 ? 'table-warning'
                  : (publicationsFromMemoir.findIndex(x => x.id === publication.id && x.ready_to_memoir === 'ready') >= 0 ? 'table-success' : '')"
            > -->
              <!-- :class="publicationsFromMemoir.findIndex(x => x.id === publication.id) >= 0 ? 'table-success' : ''" -->
              <td>
                <input
                  :checked="clicked"
                  type="checkbox"
                  @click="selectItem($event.target.checked, publication)"
                >
              </td>
              <td><small v-html="publication.full_reference" /></td>
              <td>{{ publication.year }}</td>
              <td><span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
              >{{ publication.publication_status ? publication.publication_status.name : '---' }}</span></td>
              <td><span :class="`badge bg-${publication.show_memoir ? 'success' : 'danger'}`">{{ publication.show_memoir ? 'Selected' : 'No' }}</span></td>
              <td>{{ publication.order_memoir }}</td>
              <td>{{ calculateChars(publication) }}</td>
              <td><span :class="`badge bg-${publication.signed_icrea ? 'success' : 'danger'}`">{{ publication.signed_icrea ? 'Yes' : 'No' }}</span></td>
              <td class="font-small-4">
                {{ publication.comments_icrea }}
              </td>
              <td class="text-end">
                <!-- <div class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block">
                  <button
                    type="button"
                    class="btn btn-light-secondary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i data-feather="more-vertical" />
                  </button>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a
                      href="#"
                      class="dropdown-item"
                    ><i
                      class="me-50"
                      data-feather="x"
                    /> Remove to Memoir</a>
                    <div class="dropdown-divider" />
                    <a
                      href="#"
                      class="dropdown-item"
                    ><i
                      class="me-50"
                      data-feather="edit-3"
                    /> Edit publication</a>
                  </div>
                </div> -->
                <span
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-dark"
                  @click="$router.push({name: 'researcher.publications.edit', params: { id: publication.id }})"
                ><i data-feather="edit-3" /></a></span>
                <span
                  v-if="publication.show_memoir"
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteWithInput"
                  @click="setPublications('remove', 'item', publication)"
                ><i data-feather="x" /></a></span>
                <span
                  v-else
                  class="dis-ib"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Add"
                ><a
                  class="btn btn-icon rounded-circle btn-flat-danger"
                  @click="setPublications('add', 'item', publication)"
                ><i data-feather="plus" /></a></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="d-flex align-items-center justify-content-between"> -->
      <!-- <Paginator
          :total="publicationsTotal"
          :store="'publications/filter'"
        /> -->
      <!-- Futuro -->
      <!-- <div class="mt-1">
          <select
            id=""
            name=""
            class="form-select"
          >
            <option value="">
              25 items per page
            </option>
            <option value="">
              50 items per page
            </option>
            <option value="">
              75 items per page
            </option>
            <option value="">
              All items per page
            </option>
          </select>
        </div> -->
      <!-- </div> -->
    </div>
    <!-- <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Paginator from '@/views/back/partials/Paginator.vue'
import Vue from 'vue'

export default {
  components: {
    Paginator,
    BDropdown,
    BDropdownItem,
  },
  props: {
    memoirProfileId: { type: Number, required: true, default: 0 },
    memoirProfileYear: { type: [Number, String], required: true, default: 0 },
    publicationsFromMemoir: { type: Array, required: true, default: () => [] },
    memoirProfileUser: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      filters: [],
      loading: false,
      selectedItems: [],
      clicked: false,
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/choosePublications',
      publications: 'publications/items',
      publicationsTotal: 'publications/itemsTotal',
      filtersTotal: 'filters/filters',
    }),
  },
  async mounted() {
    this.saveFilter(this.memoirProfileYear, 'start_year')
    this.saveFilter(this.memoirProfileYear, 'end_year')
    this.saveFilter(this.memoirProfileUser, 'researcher')
    this.saveFilter(true, 'showAll')
    await this.$store.dispatch('publications/filter', this.filtersTotal)
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    closeOffcanva() {
      this.$store.dispatch('modals/toggleChoosePublications', false)
    },
    async applyFilters() {
      await this.$store.dispatch('publications/filter', this.filtersTotal)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
    saveFilter(value, field) {
      if (field === 'year') {
        this.saveFilter(value, 'start_year')
        this.saveFilter(value, 'end_year')
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    cleanFilters() {
      this.$store.dispatch('filters/clearFilters')
      this.filters = []
    },
    selectItem(check, item) {
      if (check) {
        this.selectedItems.push(item)
      } else {
        this.selectedItems.splice(this.selectedItems.findIndex(x => x.id === item.id), 1)
      }
    },
    calculateChars(publication) {
      return publication.filtered_full_reference.length
    },
    selectAll(event) {
      if (event.target.checked) {
        this.clicked = true
        this.selectedItems = this.publications
      } else {
        this.clicked = false
        this.selectedItems = []
      }
    },
    async setPublications(action, data, item = null) {
      switch (action) {
        case 'add':
          if (data === 'item' && item) {
            await this.$store.dispatch('memoirsProfile/setPublications', {
              id: this.memoirProfileId, type: 'single', action, publication: item,
            })
            Vue.swal('Publication added to memoir', '', 'success').then(async () => {
              await this.$emit('needFetch', true)
              this.closeOffcanva()
            })
          } else if (data === 'items') {
            if (this.selectedItems && this.selectedItems.length > 0) {
              await this.$store.dispatch('memoirsProfile/setPublications', {
                id: this.memoirProfileId, type: 'selected', action, selectedPublications: this.selectedItems,
              })
              Vue.swal('Publications added to memoir', '', 'success').then(async () => {
                await this.$emit('needFetch', true)
                this.closeOffcanva()
              })
            } else {
              Vue.swal('No publications selected', '', 'warning')
            }
          }
          break
        case 'remove':
          if (data === 'item' && item) {
            await this.$store.dispatch('memoirsProfile/setPublications', {
              id: this.memoirProfileId, type: 'single', action, publication: item,
            })
            Vue.swal('Publication removed to memoir', '', 'success').then(async () => {
              await this.$emit('needFetch', true)
              this.closeOffcanva()
            })
          } else if (data === 'items') {
            if (this.selectedItems && this.selectedItems.length > 0) {
              await this.$store.dispatch('memoirsProfile/setPublications', {
                id: this.memoirProfileId, type: 'selected', action, selectedPublications: this.selectedItems,
              })
              Vue.swal('Publications removed to memoir', '', 'success').then(async () => {
                await this.$emit('needFetch', true)
                this.closeOffcanva()
              })
            } else {
              Vue.swal('No publications selected', '', 'warning')
            }
          }
          break
        default:
          break
      }
    },
  },
}
</script>

<style>

</style>
